isMobile = -> window.innerWidth <= 431
$ ->
  # Sticky header
  header = $('header')[0]
  window.addEventListener 'scroll', ->
    if window.pageYOffset > 0
      header.classList.add('scrolled')
    else
      header.classList.remove('scrolled')

  # Website title fade effect
  titleElement = $('#title')[0]
  window.addEventListener 'scroll', ->
    scrollPosition = window.pageYOffset || document.documentElement.scrollTop
    windowHeight = window.innerHeight
    maxOpacity = 1
    fadeStartPosition = 0
    fadeEndPosition = windowHeight
    if scrollPosition <= fadeStartPosition
      titleElement.style.opacity = maxOpacity
    else if scrollPosition >= fadeEndPosition
      titleElement.style.opacity = 0
    else
      opacityRange = fadeEndPosition - fadeStartPosition
      opacityDecrement = maxOpacity / opacityRange
      titleElement.style.opacity = maxOpacity - (scrollPosition - fadeStartPosition) * opacityDecrement

  # Pre-footer content toggle
  preFooter = $('#contact')
  preFooterHeader = preFooter.find('.pre-footer-header')
  preFooterContent = preFooter.find('.pre-footer-content')
  contactFormFields = preFooter.find('.contact-form')
  preOrderFormFields = preFooter.find('.pre-order-form')
  preFooterHeaderHeight = preFooterHeader.height()
  contactFormFields.hide()
  preOrderFormFields.hide()
  preFooterHeader.on 'click', ->
    if preFooterContent.height() == 0
      contentHeight = if isMobile()
        contactFormFields.outerHeight() + preOrderFormFields.outerHeight() + 120
      else
        contactFormFields.outerHeight() + 180
      preFooter.css('height', "#{contentHeight}px")
      preFooterContent.css('height', "#{contentHeight}px")
      contactFormFields.show()
      preOrderFormFields.show()
      setTimeout ->
        $([document.documentElement, document.body]).animate({scrollTop: preFooter.offset().top}, 600)
      , 150
    else
      preFooter.css('height', preFooterHeaderHeight)
      preFooterContent.css('height', '0px')
      contactFormFields.hide()
      preOrderFormFields.hide()
